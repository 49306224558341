import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import 'normalize.css';
// import './style.css'
import './custom.scss';

import App from './app/App';
// import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap-icons/font/bootstrap-icons.css'
import reportWebVitals from './reportWebVitals';
import { MetronicSplashScreenProvider } from './app/components/LayoutSplashScreen';

import { Translations18nProvider } from './i18n/Translations18n';

ReactDOM.render(
  <Translations18nProvider>
  <React.StrictMode>
      <MetronicSplashScreenProvider>
      <BrowserRouter>
    <App />
    </BrowserRouter>
    </MetronicSplashScreenProvider>
  </React.StrictMode>
  </Translations18nProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
