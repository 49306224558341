
import React,{useState} from "react";
import RoutesCmp from './RoutesCmp';
import WebFont from 'webfontloader';
import { I18nProvider } from './../i18n/I18nProvider';

function App() {
  const [isReadyFont1,setIsReadyFont1] =useState(false);
  

  WebFont.load({
    active: function(e) {
      
      setIsReadyFont1(true)
   
    },
    fontactive: function(familyName, fvd) {
      setIsReadyFont1(true)
      
    },
    custom :{
      families: ['CFSemplicePro','Efra']
    }
  })
  // var font = new FontFaceObserver('CFSemplicePro');

  // font.load().then(function () {

  //   console.log('Output Sans has loaded.');
  // }).catch(function () {
  //   console.log('Output Sans failed to load.');
  // });

  return (
    
    (isReadyFont1) && (
    <I18nProvider>
      <RoutesCmp />
    </I18nProvider>
      
   )
  );
}

export default App;
