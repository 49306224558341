import React, { Suspense,lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { LayoutSplashScreen } from "./components/LayoutSplashScreen";

const HomePageLazy = lazy(()=> import("./pages/HomePage/Homepage"));
const CanvasLazy = lazy(()=> import("./pages/CanvasPage/CanvasPage"));
const DetailPageLazy = lazy(()=> import("./pages/DetailPage/DetailPage"));
const OnBoardingNamePageLazy = lazy(()=> import("./pages/OnBoardingNamePage/OnBoardingNamePage"));
const OnBoardingIntroPageLazy = lazy(()=> import("./pages/OnBoardingIntroPage/OnBoardingIntroPage"));
const OnBoardingLetterPagePageLazy = lazy(()=> import("./pages/OnBoardingLetterPage/OnBoardingLetterPage"));
export default function RoutesCmp() {

  return (
   
    <Suspense fallback={<LayoutSplashScreen />}>
      <Routes>
        <Route path="/" element={
          <Suspense fallback={<LayoutSplashScreen />}>
          <HomePageLazy />
          </Suspense>
        } />
        <Route path="/paint" element={
          <Suspense fallback={<LayoutSplashScreen />}>
            <CanvasLazy />
          </Suspense>
        } />
        <Route path="/detail" element={
          <Suspense fallback={<LayoutSplashScreen />}>
            <DetailPageLazy />
          </Suspense>
        } />
        <Route path="/boardingintro" element={
          <Suspense fallback={<LayoutSplashScreen />}>
            <OnBoardingIntroPageLazy />
          </Suspense>
        } />
        <Route path="/boardingname" element={
          <Suspense fallback={<LayoutSplashScreen />}>
            <OnBoardingNamePageLazy />
          </Suspense>
        } />
        <Route path="/boardingletter" element={
          <Suspense fallback={<LayoutSplashScreen />}>
            <OnBoardingLetterPagePageLazy />
          </Suspense>
        } />
        
      </Routes>
    </Suspense>
  );
}
